import { a as a$1 } from './chunk-N4SM36VD.mjs';
import { c, b, a } from './chunk-7ICUGDQU.mjs';
import * as e from 'react';
import * as t from '@radix-ui/react-toggle-group';
import { cn } from '@sajilni/utils';
import { jsx } from 'react/jsx-runtime';

var P=e.createContext({size:"default",variant:"default"}),G=e.forwardRef((v,m)=>{var o=v,{className:r,variant:i,size:a$1,children:p}=o,g=c(o,["className","variant","size","children"]);return jsx(t.Root,b(a({ref:m,className:cn("ui-flex ui-items-center ui-justify-center ui-gap-1",r)},g),{children:jsx(P.Provider,{value:{variant:i,size:a$1},children:p})}))});G.displayName=t.Root.displayName;var T=e.forwardRef((v,m)=>{var o=v,{className:r,children:i,variant:a$2,size:p}=o,g=c(o,["className","children","variant","size"]);let f=e.useContext(P);return jsx(t.Item,b(a({ref:m,className:cn(a$1({variant:f.variant||a$2,size:f.size||p}),r)},g),{children:i}))});T.displayName=t.Item.displayName;

export { G as a, T as b };
