import { a as a$1 } from './chunk-JZXMVFCT.mjs';
import { a as a$3 } from './chunk-W3MQHQBP.mjs';
import { a as a$2 } from './chunk-UHUCIKSE.mjs';
import { a } from './chunk-QED2RIXF.mjs';
import { a as a$4 } from './chunk-W2A4DAOS.mjs';
import { Ticket } from 'lucide-react';
import { useState } from 'react';
import { jsx, jsxs } from 'react/jsx-runtime';

var s=[{name:"Jan",2022:4e3,2023:2400},{name:"Feb",2022:3e3,2023:1398},{name:"Mar",2022:2e3,2023:9800},{name:"Apr",2022:2780,2023:3908},{name:"May",2022:1890,2023:4800},{name:"Jun",2022:2390,2023:3800},{name:"Jul",2022:3490,2023:4300},{name:"Aug",2022:3490,2023:4300},{name:"Sep",2022:3490,2023:4300},{name:"Oct",2022:3490,2023:4300},{name:"Nov",2022:3490,2023:4300},{name:"Dec",2022:3490,2023:4300}],p=[{name:"Sport",value:400},{name:"Theater & Art",value:300},{name:"Cultural",value:300},{name:"Entertament",value:200}];var c=()=>{useState("day");return jsx("div",{className:"ui-space-y-6",children:jsxs("div",{className:"ui-grid ui-grid-cols-3 ui-gap-3",children:[jsx(a,{label:"RECENT SELLS",children:jsx(a$1,{data:[{label:"Event 1",description:"This is a description of the Event",Icon:Ticket,extraInfo:"1000"},{label:"Event 2",description:"This is a description of the Event",Icon:Ticket,extraInfo:"1000"},{label:"Event 3",description:"This is a description of the Event",Icon:Ticket,extraInfo:"1000"},{label:"Event 4",description:"This is a description of the Event",Icon:Ticket,extraInfo:"1000"},{label:"Event 5",description:"This is a description of the Event",Icon:Ticket,extraInfo:"1000"}]})}),jsx(a,{label:"UPCOMING EVENTS",children:jsx(a$2,{data:[{label:"Event 2",date:"8/7/2023",description:"This is a description of the event"},{label:"Event 3",date:"15/9/2023",description:"This is a description of the event"},{label:"Event 1",date:"12/12/2023",description:"This is a description of the event"}]})}),jsx(a,{label:"Popular Tickets",children:jsx(a$3,{data:p})}),jsx("div",{className:" ui-col-span-3",children:jsx(a,{label:"Revenue Analytics",children:jsx(a$4,{data:s,line:[{dataKey:"2022",stroke:"#1CBB8C",chartType:"line",type:"monotone"},{dataKey:"2023",stroke:"#98A2FF",chartType:"bar",type:"monotone"}]})})})]})})},m=c;

export { m as a };
