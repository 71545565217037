import { c as c$1, b, a } from './chunk-7ICUGDQU.mjs';
import * as d from 'react';
import * as i from '@radix-ui/react-radio-group';
import { Circle } from 'lucide-react';
import { cn } from '@sajilni/utils';
import { jsx } from 'react/jsx-runtime';

var c=d.forwardRef((l,r)=>{var t=l,{className:o}=t,m=c$1(t,["className"]);return jsx(i.Root,b(a({className:cn("ui-grid ui-gap-2",o)},m),{ref:r}))});c.displayName=i.Root.displayName;var n=d.forwardRef((t,l)=>{var p=t,{className:o,children:m}=p,r=c$1(p,["className","children"]);return jsx(i.Item,b(a({ref:l,className:cn("ui-aspect-square ui-h-4 ui-w-4 ui-rounded-full ui-border ui-border-slate-200 ui-border-slate-900 ui-text-slate-900 ui-ring-offset-white focus:ui-outline-none focus-visible:ui-ring-2 focus-visible:ui-ring-slate-400 focus-visible:ui-ring-offset-2 disabled:ui-cursor-not-allowed disabled:ui-opacity-50 dark:ui-border-slate-800 dark:ui-border-slate-50 dark:ui-text-slate-50 dark:ui-ring-offset-slate-950 dark:focus-visible:ui-ring-slate-800",o)},r),{children:jsx(i.Indicator,{className:"ui-flex ui-items-center ui-justify-center",children:jsx(Circle,{className:"ui-h-2 ui-w-2 ui-fill-current ui-text-current"})})}))});n.displayName=i.Item.displayName;

export { c as a, n as b };
