import { c, b, a } from './chunk-7ICUGDQU.mjs';
import * as m from 'react';
import * as e from '@radix-ui/react-checkbox';
import { CheckIcon } from 'lucide-react';
import { cn } from '@sajilni/utils';
import { jsx } from 'react/jsx-runtime';

var f=m.forwardRef((h,n)=>{var i=h,{className:s}=i,d=c(i,["className"]);return jsx(e.Root,b(a({ref:n,className:cn("border-primary focus-visible:ring-ring data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground peer h-4 w-4 shrink-0 rounded-sm border shadow focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",s)},d),{children:jsx(e.Indicator,{className:cn("flex items-center justify-center text-current"),children:jsx(CheckIcon,{className:"h-4 w-4"})})}))});f.displayName=e.Root.displayName;

export { f as a };
