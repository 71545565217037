import { c, b as b$1, a } from './chunk-7ICUGDQU.mjs';
import * as e from 'react';
import b from 'react-date-picker';
import { Calendar } from 'lucide-react';
import 'react-date-picker/dist/DatePicker.css';
import { useOnClickOutside } from 'usehooks-ts';
import { jsx } from 'react/jsx-runtime';

function N(p){var t=p,{date:o,onSelect:_,className:m,minDate:s}=t,u=c(t,["date","onSelect","className","minDate"]);let[n,a$1]=e.useState(!1),i=e.useRef(null);return useOnClickOutside(i,()=>{n&&a$1(!1);}),jsx("div",{className:" ui-relative w-full ",children:jsx(b,b$1(a({calendarIcon:jsx(Calendar,{className:"h-5 w-5 text-[#667085] !p-0 "}),onChange:_,value:o,clearIcon:null,className:"ui-text-gray-900 !ui-text-base ui-flex ui-items-center !ui-h-11 !ui-w-full !ui-rounded-lg !ui-border !ui-border-[#D0D5DD] ui-bg-white  ui-px-[14px] !ui-py-[10px] ui-ring-offset-white file:ui-border-0 file:ui-bg-transparent file:ui-text-sm file:ui-font-medium placeholder:ui-text-slate-500  focus-visible:ui-ring-1 focus-visible:!ui-ring-primary focus-visible:!ui-border-primary disabled:ui-cursor-not-allowed disabled:ui-opacity-50 dark:ui-border-slate-800 dark:ui-bg-slate-950 dark:ui-ring-offset-slate-950 dark:placeholder:ui-text-slate-400 dark:focus-visible:ui-ring-slate-800"},u),{minDate:s,inputRef:i,isOpen:n,shouldCloseCalendar:f=>f.reason!=="outsideAction",onCalendarOpen:()=>{a$1(!0);},onCalendarClose:()=>{a$1(!1);}}))})}

export { N as a };
