import { a as a$1 } from './chunk-7372RLWW.mjs';
import { a, b, c } from './chunk-BDFVUTXK.mjs';
import { b as b$1 } from './chunk-UE7CAWAC.mjs';
import { Calendar } from 'lucide-react';
import { format } from 'date-fns';
export { addDays as a, format as b } from 'date-fns';
import { cn } from '@sajilni/utils';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';

var C=({className:l,date:o,onSelect:u})=>jsx("div",{className:cn("ui-grid ui-gap-2",l),children:jsxs(a,{children:[jsx(b,{asChild:!0,children:jsxs(b$1,{id:"date",variant:"outlined",className:cn("ui-w-[300px] ui-justify-start ui-text-left ui-font-normal",!o&&"ui-text-muted-foreground"),children:[jsx(Calendar,{className:"ui-mr-2 ui-h-4 ui-w-4"}),o!=null&&o.from?o.to?jsxs(Fragment,{children:[format(o.from,"LLL dd, y")," -"," ",format(o.to,"LLL dd, y")]}):format(o.from,"LLL dd, y"):jsx("span",{children:"Pick a date"})]})}),jsx(c,{className:"ui-w-auto ui-p-0",align:"start",children:jsx(a$1,{initialFocus:!0,mode:"range",defaultMonth:o==null?void 0:o.from,selected:o,onSelect:u,numberOfMonths:2})})]})});

export { C as c };
