import { c, a, b as b$1 } from './chunk-7ICUGDQU.mjs';
import * as n from 'react';
import { Slot } from '@radix-ui/react-slot';
import { ChevronRight, MoreHorizontal } from 'lucide-react';
import { cn } from '@sajilni/utils';
import { jsx, jsxs } from 'react/jsx-runtime';

var R=n.forwardRef((t,a$1)=>{var e=c(t,[]);return jsx("nav",a({ref:a$1,"aria-label":"breadcrumb"},e))});R.displayName="Breadcrumb";var b=n.forwardRef((s,t)=>{var r=s,{className:e}=r,a$1=c(r,["className"]);return jsx("ol",a({ref:t,className:cn("ui-flex ui-flex-wrap ui-items-center ui-gap-1.5 ui-break-words ui-text-sm ui-text-slate-500 sm:ui-gap-2.5 dark:ui-text-slate-400",e)},a$1))});b.displayName="BreadcrumbList";var N=n.forwardRef((s,t)=>{var r=s,{className:e}=r,a$1=c(r,["className"]);return jsx("li",a({ref:t,className:cn("ui-inline-flex ui-items-center ui-gap-1.5",e)},a$1))});N.displayName="BreadcrumbItem";var B=n.forwardRef((r,s)=>{var c$1=r,{asChild:e,className:a$1}=c$1,t=c(c$1,["asChild","className"]);return jsx(e?Slot:"a",a({ref:s,className:cn("ui-transition-colors hover:ui-text-slate-950 dark:hover:ui-text-slate-50",a$1)},t))});B.displayName="BreadcrumbLink";var h=n.forwardRef((s,t)=>{var r=s,{className:e}=r,a$1=c(r,["className"]);return jsx("span",a({ref:t,role:"link","aria-disabled":"true","aria-current":"page",className:cn("ui-font-normal ui-text-slate-950 dark:ui-text-slate-50",e)},a$1))});h.displayName="BreadcrumbPage";var x=s=>{var r=s,{children:e,className:a$1}=r,t=c(r,["children","className"]);return jsx("li",b$1(a({role:"presentation","aria-hidden":"true",className:cn("[&>svg]:ui-size-3.5",a$1)},t),{children:e!=null?e:jsx(ChevronRight,{})}))};x.displayName="BreadcrumbSeparator";var C=t=>{var s=t,{className:e}=s,a$1=c(s,["className"]);return jsxs("span",b$1(a({role:"presentation","aria-hidden":"true",className:cn("ui-flex ui-h-9 ui-w-9 ui-items-center ui-justify-center",e)},a$1),{children:[jsx(MoreHorizontal,{className:"ui-h-4 ui-w-4"}),jsx("span",{className:"ui-sr-only",children:"More"})]}))};C.displayName="BreadcrumbElipssis";

export { R as a, b, N as c, B as d, h as e, x as f, C as g };
