import { c, a, b } from './chunk-7ICUGDQU.mjs';
import * as d from 'react';
import * as i from '@radix-ui/react-accordion';
import { ChevronDown } from 'lucide-react';
import { cn } from '@sajilni/utils';
import { jsx, jsxs } from 'react/jsx-runtime';

var C=i.Root,v=d.forwardRef((c$1,r)=>{var t=c$1,{className:e}=t,o=c(t,["className"]);return jsx(i.Item,a({ref:r,className:cn("ui-border-b",e)},o))});v.displayName="AccordionItem";var A=d.forwardRef((s,t)=>{var p=s,{className:e,children:o,showChevron:r=!0}=p,c$1=c(p,["className","children","showChevron"]);return jsx(i.Header,{className:"ui-flex",children:jsxs(i.Trigger,b(a({ref:t,className:cn("ui-flex ui-flex-1 ui-items-center ui-justify-between ui-py-4 ui-font-medium ui-transition-all hover:ui-underline [&[data-state=open]>svg]:ui-rotate-180",e)},c$1),{children:[r&&jsx(ChevronDown,{className:"ui-mr-2 ui-h-4 ui-w-4 ui-shrink-0 ui-transition-transform ui-duration-200"}),o]}))})});A.displayName=i.Trigger.displayName;var P=d.forwardRef((t,c$1)=>{var s=t,{className:e,children:o}=s,r=c(s,["className","children"]);return jsx(i.Content,b(a({ref:c$1,className:cn("data-[state=closed]:ui-animate-accordion-up data-[state=open]:ui-animate-accordion-down ui-overflow-hidden ui-text-sm ui-transition-all",e)},r),{children:jsx("div",{className:"ui-pb-4 ui-pt-0",children:o})}))});P.displayName=i.Content.displayName;

export { C as a, v as b, A as c, P as d };
